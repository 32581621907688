import { useConfigurationStore } from "~/stores/configurationStore";
import { useJourneyConfigurationStore } from "~/stores/journeyConfigurationStore";
import { UserJourneyService } from "~/services/UserJourneyService";
import { QueryStringHelper } from "~/helpers/QueryStringHelper";
import { ErrorCode } from "../enum/ErrorCode";
import { JourneyStep } from "~/enum/JourneyStep";

export default defineNuxtRouteMiddleware(async (to: any) => {

    const configurationStore = useConfigurationStore();
    const configuration = configurationStore.config;

    if (window.location.hostname.startsWith('onboarding-ui.')) {
        const redirectToWWW = 'https://' + window.location.hostname.replace('onboarding-ui.', 'www.') + to.fullPath;
        return navigateTo(redirectToWWW, {
            external: true,
            redirectCode: 301
        });
    }

    const redirection = mustBeRedirectedPage(window.location.pathname);
    if (redirection != undefined && redirection != null) {
        const currentParams = QueryStringHelper.GetAllQueryStringParameters();

        if (currentParams == '') {
            return navigateTo(redirection, {
                external: true
            });
        }
        else {
            return navigateTo(redirection + '?' + currentParams, {
                external: true
            });
        }
    }

    if (mustBeAnonymousPage(to.name)) {
        if (checkCookies()) {
            const userJourney = await UserJourneyService.getUserJourney();

            if (userJourney?.journeyId && userJourney.journeyId > 0) {
                const journeyConfigurationStore = useJourneyConfigurationStore();
                await journeyConfigurationStore.fetchData(userJourney.journeyId);

                navigateTo(UserJourneyService.goToJourneyStep(userJourney.currentStepId), {
                    external: true
                });
            }
            else if (userJourney?.errorCode && userJourney.errorCode === ErrorCode.UserNotProperlyAuthenticated) {
                console.error("Cookie not properly set; trying to delete it to continue.");

                const domain = configuration.domainProperties.domain;
                deleteCookies(domain);

                if (!checkCookies()) {

                    var redirectTo = "/people/login";
                    const currentParams = QueryStringHelper.GetAllQueryStringParameters();

                    if (currentParams != '') {
                        redirectTo = redirectTo + '?' + currentParams;
                    }

                    return navigateTo(redirectTo);
                }
                else {
                    console.error("Cookie was imposible to delete.");
                }
            }
            else if (!mustNotBeRedirectedWhenLoggedPage(to.name)) {
                const returnUrl = QueryStringHelper.GetReturnUrl();

                if (returnUrl == null || returnUrl == '') {
                    return navigateTo(configuration.domainProperties.defaultWebsite, {
                        external: true
                    });
                }
                else {
                    return navigateTo(returnUrl, {
                        external: true
                    });
                }
            }
        }
        else if (to.name == "code-validation" || to.name == "/register/code-validation") {
            const userId = QueryStringHelper.GetRequestUserId();
            if (userId) {
                const userJourney = await UserJourneyService.getUserJourneyByUserId(Number(userId));
                if (userJourney?.journeyId && userJourney.journeyId > 0) {
                    const journeyConfigurationStore = useJourneyConfigurationStore();
                    await journeyConfigurationStore.fetchData(userJourney.journeyId);
                }
            }
        }
    } else {
        if (!checkCookies()) {
            
            var redirectTo = "/people/login";
            const currentParams = QueryStringHelper.GetAllQueryStringParameters();

            if (currentParams != '') {
                redirectTo = redirectTo + '?' + currentParams;
            }

            return navigateTo(redirectTo);
        }
        if (to.name == "profile-picture" && checkCookies()) {
            const journeyConfigurationStore = useJourneyConfigurationStore();
            const journeyConfiguration = journeyConfigurationStore.config;
            const journeySteps = journeyConfiguration?.journeySteps;
            if (journeySteps) {
                if (!journeySteps.find((x: any) => x.stepId == JourneyStep.AddPicture)) {
                    return navigateTo(configuration.domainProperties.defaultWebsite, {
                        external: true
                    });
                }
            }
        }

        if (checkCookies()) {
            const userJourney = await UserJourneyService.getUserJourney();
            if (userJourney?.journeyId && userJourney.journeyId > 0) {
                const journeyConfigurationStore = useJourneyConfigurationStore();
                await journeyConfigurationStore.fetchData(userJourney.journeyId);
            }
        }        
    }

    return;
});

function checkCookies() {
    const cookies = document.cookie.split(";");
    return !!cookies.find((cookie) => cookie.trim().startsWith("Cerberus"));
}

function deleteCookies(domain: string) {
    document.cookie = "Cerberus=; Path=/; Domain=." + domain + "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function mustBeAnonymousPage(pageName: string) {
    switch (pageName) {
        case "login":
        case "step-1":
        case "register":
        case "code-validation":
        case "reset-code-validation":
        case "link-account":
        case "forgot":
        case "set-password":
        case "terms-and-conditions":
        case "request":
            return true;
        default:
            return false;
    }
}

function mustNotBeRedirectedWhenLoggedPage(pageName: string) {
    switch (pageName) {
        case "terms-and-conditions":
        case "request":
            return true;
        default:
            return false;
    }
}

function mustBeRedirectedPage(pageName: string) {
    switch (pageName) {
        case "/register/resend-activation-email":
        case "/register/activate":
            return "/register/code-validation";
        default:
            return null;
    }
}