import type { JourneyModel } from "../models/JourneyConfigurationResult";

export class ConfigurationService {
    public static async getConfiguration(): Promise<any> {
        try {
            const response = await fetch("/api/onboarding-ui/v1/configuration/configurationproperties");
            const configurationProperties = await response.json();
            return configurationProperties;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    public static async getJourneyConfiguration(journeyId: number): Promise<JourneyModel | undefined> {
        try {
            const response = await fetch(`/api/onboarding-ui/v1/configuration/journey/${journeyId}`);
            const jsonResponse = await response.json();
            const journeyConfiguration: JourneyModel = jsonResponse;
            return journeyConfiguration;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
