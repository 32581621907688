export default class RegistrationResult {
    constructor({ callbackUrl, journeyId, currentStepId, errorCode }) {
        this.callbackUrl = callbackUrl;
        this.journeyId = journeyId;
        this.currentStepId = currentStepId;
        this.errorCode = errorCode;
    }

    callbackUrl: string;
    journeyId: number;
    currentStepId: number;
    errorCode: number | null;
}