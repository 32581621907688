import { ConfigurationService } from "~/services/ConfigurationService";
import { defineStore } from "pinia";
import { JourneyStep } from "~/enum/JourneyStep";
import { UserJourneyService } from "~/services/UserJourneyService";

const CLEAR_STORAGE_DELAY = 300000;
export const useJourneyConfigurationStore = defineStore("journeyConfiguration", { 
    state: () => ({
        config: null,
        isLoading: false,
        timeoutId: undefined
    }),
    persist: {
        storage: persistedState.localStorage
    },
    getters: {
        configuration: state => state.config
    },
    actions: {
        async fetchData(journeyId: number | null): Promise<void> {
            try {
                if (journeyId != null) {
                    if (this.config == null || this.config.journeyId != journeyId) {
                        this.isLoading = true;
                        const response = await ConfigurationService.getJourneyConfiguration(journeyId);
                        this.config = response;

                        if (this.timeoutId != undefined) {
                            clearTimeout(this.timeoutId);
                        }

                        this.scheduleStorageClear();
                    }

                    if (this.config.journeyId == journeyId) {
                        clearTimeout(this.timeoutId);
                        this.scheduleStorageClear();
                    }
                }
                else  {
                    this.config = null;
                }
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },
        scheduleStorageClear() {
            this.timeoutId = setTimeout(() => {
                localStorage.removeItem("pinia");
                this.config = null;
            }, CLEAR_STORAGE_DELAY);
        },
        async getNextStep(currentStep: number): Promise<string | null> {
            try {
                await UserJourneyService.updateCurrentStep(currentStep);

                if (currentStep == JourneyStep.VerifyInstitutionalEmail || currentStep == JourneyStep.VerifyORCID) {
                    currentStep = Math.max(JourneyStep.VerifyInstitutionalEmail, JourneyStep.VerifyORCID);
                }

                if (!this.config || !this.config.journeySteps) {
                    const userJourney = await UserJourneyService.getUserJourney();
                    return userJourney?.callbackUrl;
                }

                const steps = this.config.journeySteps;
                const currentIndex = steps.findIndex(step => step.stepId === currentStep);

                var nextStepId: number = 0;

                if (currentIndex === -1 || currentIndex >= steps.length - 1) {
                    var i = 0;
                    while (i <= steps.length - 1 && nextStepId == 0) {
                        if (steps[i].stepId > currentStep) {
                            nextStepId = steps[i].stepId;
                        }
                        i++;
                    }
                }
                else {
                    nextStepId = steps[currentIndex + 1].stepId;
                }

                if (nextStepId == 0) {
                    const userJourney = await UserJourneyService.getUserJourney();
                    return userJourney?.callbackUrl;
                }

                return UserJourneyService.goToJourneyStep(nextStepId);
            } catch (error) {
                var errorToLog = new Error("getNextStep - Error fetching next step for current step " + currentStep.toString() + ": " + error);
                console.error(errorToLog);
                newrelic.noticeError(errorToLog);
                const configuration = await ConfigurationService.getConfiguration();
                return configuration?.domainProperties.defaultWebsite;
            }
        },
        async finishJourney() {
            try {
                await UserJourneyService.finishUserJourney();
                if (this.timeoutId != undefined) {
                    clearTimeout(this.timeoutId);
                }
                this.config = null;
            } catch (error) {
                var errorToLog = new Error("finishJourney: " + error);
                console.error(errorToLog);
                newrelic.noticeError(errorToLog);
            }
        }
    }  
  });