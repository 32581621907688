export enum ErrorCode {
    InvalidVerificationCode = 2,
    AuthenticationFailed = 6,
    AccountInactiveOrNotVerified = 7,
    UserSuspended = 8,
    UserNotProperlyAuthenticated = 10,
    UserAlreadyActivated = 14,
    AlreadyExistingAccount = 16,
    UserNotLinkedToTenant = 66,
    ImpersonatedUser = 85,
    UserBlockedGenerationTriesReached = 93,
    UserBlockedVerificationTriesReached = 95,
    ThirdPartyAuthenticationFailed = 96,
    InvalidCaptcha = 97,
    EmailAlreadyVerified = 104,
    CheckSpam = 109
}
