import { JourneyStep } from "~/enum/JourneyStep";
import UserJourneyResult from "~/models/UserJourneyResult";

export class UserJourneyService {
    public static async updateCurrentStep(currentStepId: number): Promise<any> {
        try {
            await fetch("/api/onboarding-ui/v1/userjourney/steps/" + currentStepId, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            console.error("Error on updating current step in the user journey: " + e);
            throw e;
        }
    }

    public static goToJourneyStep(stepId: number) {
        switch (stepId) {
            case JourneyStep.VerifyInstitutionalEmail:
            case JourneyStep.VerifyORCID:
                return "/register/research-accounts";
            case JourneyStep.ConfirmPublications:
                return "/register/confirm-publications";
            case JourneyStep.AddPicture:
                return "/register/profile-picture";
            default:
                ""
                break;
        }
    }

    public static async finishUserJourney() {
        try {
            await fetch("/api/onboarding-ui/v1/userjourney", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            console.error("Error finishing user journey: " + e);
            throw e;
        }
    }

    public static async getUserJourney(): Promise<UserJourneyResult | null> {
        try {
            const response = await fetch("/api/onboarding-ui/v1/userjourney", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
               return await response.json();
            }
            else if (response.status === 412) {
                const errorResult = await response.json();
                const errorCode = errorResult.Code;
                return new UserJourneyResult({ callbackUrl: "", journeyId: 0, currentStepId: 0, errorCode: errorCode });
            }
            else {
                return null;
            }
        } catch (e) {
            console.error("Error getting user journey: " + e);
            throw e;
        }
    }

    public static async getUserJourneyByUserId(userId: number): Promise<UserJourneyResult | null> {
        try {
            const response = await fetch("/api/onboarding-ui/v1/userjourney/" + userId.toString(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                return await response.json();
            }
            else if (response.status === 404) {
                return null;
            }
            else {
                throw new Error(`Error getting user journey for userId: ${userId}.`);
            }
        } catch (e) {
            console.error("Error getting user journey: " + e);
            throw e;
        }
    }


}
