import { Plugins, Directives } from "@frontiers-vue3/public-pages";
import { defineNuxtPlugin } from "nuxt/app";
import { useConfigurationStore } from "~/stores/configurationStore";

export default defineNuxtPlugin(async (nuxtApp) => {

    nuxtApp.vueApp.use(Plugins.GoogleAnalyticsPlugin);
    nuxtApp.vueApp.use(Plugins.SanitizeHtmlPlugin);
    nuxtApp.vueApp.directive("tooltip", Directives.Tooltip);

    const configurationStore = useConfigurationStore();
    if (configurationStore.config == null) {
        await configurationStore.fetchData();
    }    
});
