import { $frontiersClibConfig } from "@frontiers-vue3/public-pages";
import { defineNuxtPlugin } from "nuxt/app";
import { useConfigurationStore } from "~/stores/configurationStore";

/**
 * This config is used for frontiers-vue3/public-pages library components
 */

export default defineNuxtPlugin(async () => {
    const isDevelopmentMode = process.env.NODE_ENV === "development";

    const configurationStore = useConfigurationStore();
    const configuration = configurationStore.config;

    $frontiersClibConfig.setConfig({
        isFrontiers: configuration.domainProperties.domain.includes("frontiers"),
        tenantName: configuration.domainProperties.spaceName,
        mainDomain: configuration.domainProperties.domain,
        loopUrl: configuration.domainProperties.loopUrl,
        ssMainDomain: configuration.domainProperties.ssMainDomain,
        useWwwAbsoluteUrls: !isDevelopmentMode
    });
});
