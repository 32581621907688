<script lang="ts" setup>

    const nuxtApp = useNuxtApp();
    const loading = ref(true);

    import { useConfigurationStore } from "~/stores/configurationStore";

    const configurationStore = useConfigurationStore();

    onMounted(async () => {

        const configuration = configurationStore.config;

        useHead({
            script: [
                {
                    children: "dataLayer = [];dataLayer.push({'app_id':" + configuration.gtmProperties.spaceId + "});"
                },
                {
                    children: "(function (w, d, s, l, i) {w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true;j.src = '" + configuration.gtmProperties.serverUrl + "/gtm.js?id=' + i + dl + '&gtm_auth=" + configuration.gtmProperties.auth + "&gtm_preview=" + configuration.gtmProperties.environment + "&gtm_cookies_win=x';f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', '" + configuration.gtmProperties.containerId + "');"
                },
                {
                    children: "; window.NREUM || (NREUM = {}); NREUM.init = { distributed_tracing: { enabled: true }, privacy: { cookies_enabled: true }, ajax: { deny_list: ['bam.nr-data.net'] } };; NREUM.loader_config = { accountID: '" + configuration.newRelicBrowserProperties.accountId + "', trustKey: '" + configuration.newRelicBrowserProperties.trustKey + "', agentID: '" + configuration.newRelicBrowserProperties.agentId + "', licenseKey: '" + configuration.newRelicBrowserProperties.licenseKey + "', applicationID: '" + configuration.newRelicBrowserProperties.applicationId + "' };; NREUM.info = { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: '" + configuration.newRelicBrowserProperties.licenseKey + "', applicationID: '" + configuration.newRelicBrowserProperties.applicationId + "', sa: 1 }; "
                },
                {
                    hid: "new_relic_func",
                    src: "register/newRelicNuxt.js", //to-do: revisit the path: using "register" as it's one of the redirections to onboarding app set in the HA proxy
                    defer: true,
                    type: "text/javascript"
                },                
            ],
        });
    })

    nuxtApp.hook("page:start", () => {
        loading.value = true;
    });

    nuxtApp.hook("page:finish", () => {
        loading.value = false;
    });

    nuxtApp.hook("app:mounted", () => {
        // Event is fired when the app is mounted
    });

</script>
<template>
    <NuxtLayout />
</template>