import { ConfigurationService } from "~/services/ConfigurationService";
import { defineStore } from "pinia";

export const useConfigurationStore = defineStore("configuration", { 
    state: () => ({
        config: null
    }),
    getters: {
        configuration: state => state.config
    },
    actions: {
      async fetchData(): Promise<void> {
        try {
            const response = await ConfigurationService.getConfiguration();
            this.config = response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    }  
  });