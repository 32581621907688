import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_se6gvStgvS from "/nuxt/dist/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _00_error_handler_clib_bKSWQVmKj9 from "/app/plugins/00.error-handler-clib.ts";
import _01_common_clib_FU35MTzoeC from "/app/plugins/01.common-clib.ts";
import _02_frontiers_clib_config_h3EJaxLS0a from "/app/plugins/02.frontiers-clib-config.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_se6gvStgvS,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  _00_error_handler_clib_bKSWQVmKj9,
  _01_common_clib_FU35MTzoeC,
  _02_frontiers_clib_config_h3EJaxLS0a
]