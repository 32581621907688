export class QueryStringHelper {
    static GetRequestAuthToken(): string | null {
        const currentParams = new URLSearchParams(window.location.search);
        var requestAuthToken = this.GetCaseInsensitiveParamValue(currentParams, 'requestauthtoken');

        if (requestAuthToken == null) {
            requestAuthToken = this.GetCaseInsensitiveParamValue(currentParams, 'token');
        }

        return requestAuthToken;
    }

    static GetRequestUserId(): string | null {
        const currentParams = new URLSearchParams(window.location.search);
        const userId = this.GetCaseInsensitiveParamValue(currentParams, 'userid');
        return userId;
    }

    static GetReturnUrl(): string | null {
        const returnUrlParamName = "returnurl";
        const currentParams = new URLSearchParams(window.location.search);
        const returnUrl = this.GetCaseInsensitiveParamValue(currentParams, returnUrlParamName);
        if (returnUrl && returnUrl != "") {

            if (returnUrl.indexOf("?") > -1 && !this.isAbsoluteUrl(returnUrl)) {

                const [baseUrl, existingQuery] = returnUrl.split('?');
                const existingParams = new URLSearchParams(existingQuery || '');

                currentParams.forEach((value, key) => {
                    if (key !== 'returnUrl') {
                        existingParams.set(key, value);
                    }
                });

                const reconstructedUrl = `${baseUrl}?${existingParams.toString()}`;

                return reconstructedUrl;
            }
            else {
                return returnUrl;
            }
        }
        return null;
    }

    static isAbsoluteUrl(urlString: string): boolean {
        try {
            new URL(urlString);
            return true;
        } catch (_) {
            return false;
        }
    }

    static GetAllQueryStringParameters(): URLSearchParams {
        const currentParams = new URLSearchParams(window.location.search);
        return currentParams;
    }

    private static GetCaseInsensitiveParamValue(urlParams: URLSearchParams, paramName: string): string | null {
        let paramValue: string | null = null;

        urlParams.forEach((value, key) => {
            if (key.toLowerCase() == paramName.toLowerCase()) {
                paramValue = value;
            }
        });

        return paramValue;
    }
}