import { default as addProfilePictureKJALUYOIA3Meta } from "/app/pages/addProfilePicture.vue?macro=true";
import { default as codeValidationjcmfOEpXGBMeta } from "/app/pages/codeValidation.vue?macro=true";
import { default as confirmInformationContinueGTS4Z7VnV9Meta } from "/app/pages/confirmInformationContinue.vue?macro=true";
import { default as confirmPublicationsYTwkgb4arOMeta } from "/app/pages/confirmPublications.vue?macro=true";
import { default as forgotPasswordK3LzTMqsvhMeta } from "/app/pages/forgotPassword.vue?macro=true";
import { default as institutionalEmailCodeValidationEO2UYQFaCTMeta } from "/app/pages/institutionalEmailCodeValidation.vue?macro=true";
import { default as institutionalEmailConfirmationzz6vpRK6RWMeta } from "/app/pages/institutionalEmailConfirmation.vue?macro=true";
import { default as linkAccount5BINnPNvswMeta } from "/app/pages/linkAccount.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as registrationre4qBsMVLdMeta } from "/app/pages/registration.vue?macro=true";
import { default as resetPasswordCodeValidationVdQiBO0HJBMeta } from "/app/pages/resetPasswordCodeValidation.vue?macro=true";
import { default as setPasswordxYUL2wxQleMeta } from "/app/pages/setPassword.vue?macro=true";
import { default as step0RVKjqmt22YMeta } from "/app/pages/step0.vue?macro=true";
import { default as termsAndConditionstqfA5uDYjHMeta } from "/app/pages/termsAndConditions.vue?macro=true";
import { default as verifyIdentitykzgNkstKOQMeta } from "/app/pages/verifyIdentity.vue?macro=true";
export default [
  {
    name: addProfilePictureKJALUYOIA3Meta?.name ?? "addProfilePicture",
    path: addProfilePictureKJALUYOIA3Meta?.path ?? "/addProfilePicture",
    meta: addProfilePictureKJALUYOIA3Meta || {},
    alias: addProfilePictureKJALUYOIA3Meta?.alias || [],
    redirect: addProfilePictureKJALUYOIA3Meta?.redirect || undefined,
    component: () => import("/app/pages/addProfilePicture.vue").then(m => m.default || m)
  },
  {
    name: codeValidationjcmfOEpXGBMeta?.name ?? "codeValidation",
    path: codeValidationjcmfOEpXGBMeta?.path ?? "/codeValidation",
    meta: codeValidationjcmfOEpXGBMeta || {},
    alias: codeValidationjcmfOEpXGBMeta?.alias || [],
    redirect: codeValidationjcmfOEpXGBMeta?.redirect || undefined,
    component: () => import("/app/pages/codeValidation.vue").then(m => m.default || m)
  },
  {
    name: confirmInformationContinueGTS4Z7VnV9Meta?.name ?? "confirmInformationContinue",
    path: confirmInformationContinueGTS4Z7VnV9Meta?.path ?? "/confirmInformationContinue",
    meta: confirmInformationContinueGTS4Z7VnV9Meta || {},
    alias: confirmInformationContinueGTS4Z7VnV9Meta?.alias || [],
    redirect: confirmInformationContinueGTS4Z7VnV9Meta?.redirect || undefined,
    component: () => import("/app/pages/confirmInformationContinue.vue").then(m => m.default || m)
  },
  {
    name: confirmPublicationsYTwkgb4arOMeta?.name ?? "confirmPublications",
    path: confirmPublicationsYTwkgb4arOMeta?.path ?? "/confirmPublications",
    meta: confirmPublicationsYTwkgb4arOMeta || {},
    alias: confirmPublicationsYTwkgb4arOMeta?.alias || [],
    redirect: confirmPublicationsYTwkgb4arOMeta?.redirect || undefined,
    component: () => import("/app/pages/confirmPublications.vue").then(m => m.default || m)
  },
  {
    name: forgotPasswordK3LzTMqsvhMeta?.name ?? "forgotPassword",
    path: forgotPasswordK3LzTMqsvhMeta?.path ?? "/forgotPassword",
    meta: forgotPasswordK3LzTMqsvhMeta || {},
    alias: forgotPasswordK3LzTMqsvhMeta?.alias || [],
    redirect: forgotPasswordK3LzTMqsvhMeta?.redirect || undefined,
    component: () => import("/app/pages/forgotPassword.vue").then(m => m.default || m)
  },
  {
    name: institutionalEmailCodeValidationEO2UYQFaCTMeta?.name ?? "institutionalEmailCodeValidation",
    path: institutionalEmailCodeValidationEO2UYQFaCTMeta?.path ?? "/institutionalEmailCodeValidation",
    meta: institutionalEmailCodeValidationEO2UYQFaCTMeta || {},
    alias: institutionalEmailCodeValidationEO2UYQFaCTMeta?.alias || [],
    redirect: institutionalEmailCodeValidationEO2UYQFaCTMeta?.redirect || undefined,
    component: () => import("/app/pages/institutionalEmailCodeValidation.vue").then(m => m.default || m)
  },
  {
    name: institutionalEmailConfirmationzz6vpRK6RWMeta?.name ?? "institutionalEmailConfirmation",
    path: institutionalEmailConfirmationzz6vpRK6RWMeta?.path ?? "/institutionalEmailConfirmation",
    meta: institutionalEmailConfirmationzz6vpRK6RWMeta || {},
    alias: institutionalEmailConfirmationzz6vpRK6RWMeta?.alias || [],
    redirect: institutionalEmailConfirmationzz6vpRK6RWMeta?.redirect || undefined,
    component: () => import("/app/pages/institutionalEmailConfirmation.vue").then(m => m.default || m)
  },
  {
    name: linkAccount5BINnPNvswMeta?.name ?? "linkAccount",
    path: linkAccount5BINnPNvswMeta?.path ?? "/linkAccount",
    meta: linkAccount5BINnPNvswMeta || {},
    alias: linkAccount5BINnPNvswMeta?.alias || [],
    redirect: linkAccount5BINnPNvswMeta?.redirect || undefined,
    component: () => import("/app/pages/linkAccount.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout",
    path: logoutHaVXWqg8UAMeta?.path ?? "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect || undefined,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: registrationre4qBsMVLdMeta?.name ?? "registration",
    path: registrationre4qBsMVLdMeta?.path ?? "/registration",
    meta: registrationre4qBsMVLdMeta || {},
    alias: registrationre4qBsMVLdMeta?.alias || [],
    redirect: registrationre4qBsMVLdMeta?.redirect || undefined,
    component: () => import("/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordCodeValidationVdQiBO0HJBMeta?.name ?? "resetPasswordCodeValidation",
    path: resetPasswordCodeValidationVdQiBO0HJBMeta?.path ?? "/resetPasswordCodeValidation",
    meta: resetPasswordCodeValidationVdQiBO0HJBMeta || {},
    alias: resetPasswordCodeValidationVdQiBO0HJBMeta?.alias || [],
    redirect: resetPasswordCodeValidationVdQiBO0HJBMeta?.redirect || undefined,
    component: () => import("/app/pages/resetPasswordCodeValidation.vue").then(m => m.default || m)
  },
  {
    name: setPasswordxYUL2wxQleMeta?.name ?? "setPassword",
    path: setPasswordxYUL2wxQleMeta?.path ?? "/setPassword",
    meta: setPasswordxYUL2wxQleMeta || {},
    alias: setPasswordxYUL2wxQleMeta?.alias || [],
    redirect: setPasswordxYUL2wxQleMeta?.redirect || undefined,
    component: () => import("/app/pages/setPassword.vue").then(m => m.default || m)
  },
  {
    name: step0RVKjqmt22YMeta?.name ?? "step0",
    path: step0RVKjqmt22YMeta?.path ?? "/step0",
    meta: step0RVKjqmt22YMeta || {},
    alias: step0RVKjqmt22YMeta?.alias || [],
    redirect: step0RVKjqmt22YMeta?.redirect || undefined,
    component: () => import("/app/pages/step0.vue").then(m => m.default || m)
  },
  {
    name: termsAndConditionstqfA5uDYjHMeta?.name ?? "termsAndConditions",
    path: termsAndConditionstqfA5uDYjHMeta?.path ?? "/termsAndConditions",
    meta: termsAndConditionstqfA5uDYjHMeta || {},
    alias: termsAndConditionstqfA5uDYjHMeta?.alias || [],
    redirect: termsAndConditionstqfA5uDYjHMeta?.redirect || undefined,
    component: () => import("/app/pages/termsAndConditions.vue").then(m => m.default || m)
  },
  {
    name: verifyIdentitykzgNkstKOQMeta?.name ?? "verifyIdentity",
    path: verifyIdentitykzgNkstKOQMeta?.path ?? "/verifyIdentity",
    meta: verifyIdentitykzgNkstKOQMeta || {},
    alias: verifyIdentitykzgNkstKOQMeta?.alias || [],
    redirect: verifyIdentitykzgNkstKOQMeta?.redirect || undefined,
    component: () => import("/app/pages/verifyIdentity.vue").then(m => m.default || m)
  }
]